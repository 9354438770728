
// External imports
import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';

// Local imports
import { ModelData } from '@/store/outfit';

//////////////////////////////////////////////////////////////////////////////////////////

import { cmsEndpoints, CMS_URL } from '@/config/config';
import { UserStore } from '@/store';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface ModelSelectorProps extends BaseTextFieldProps {
  model: ModelData | null;
  setModel: React.Dispatch<React.SetStateAction<ModelData | null>>;
}

// Component definition
function ModelSelector( { model, setModel, ...textFieldProps}: ModelSelectorProps ) {
  const user = React.useContext(UserStore);
  const [models, setModels] = React.useState<ModelData[]>([]);

  const initModels = React.useCallback(async () => {
    const response = await fetch(`${CMS_URL}${cmsEndpoints.models}`, {
      headers: {
        'Authorization': `Bearer ${user.jwt}`,
      },
    });
    if (response.status === 200) {
      const data = (await response.json());//['data'].attributes as Array<TagOutfit>;
      // console.log('--------------------------');
      // console.log(data);
      // console.log('--------------------------');
      const _models: ModelData[] = [];
      data.data.forEach((item: any) => {
        // console.log(item.attributes.slug);
        _models.push({
          id: item.id,
          slug: item.attributes.slug,
          name: item.attributes.name,
          createdAt: item.attributes.createdAt,
        });
      })
      setModels(_models);
    }
  }, [user.jwt]);

  React.useEffect(() => {
    initModels();
  }, [initModels]);

  return (
    <Box>
      <Autocomplete
        //filterOptions={useFilterOptions}
        value={model}
        onChange={(event: React.SyntheticEvent, newValue: ModelData | null) => { setModel(newValue) }}
        options={models}
        getOptionLabel={(option) => { return option.name }}
        renderInput={(params) => (
            <TextField {...params} {...textFieldProps} label='Model' variant="outlined"/>
        )}
      />
    </Box>
  );
}

// Default export
export default ModelSelector;
