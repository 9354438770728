import React from 'react';
// import { useMeasure } from 'react-use';
// import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import IconButton from '@mui/material/IconButton';
// import MenuItem from '@mui/material/MenuItem';
// import TextField from '@mui/material/TextField';
// import Tooltip from '@mui/material/Tooltip';

// import AddIcon from '@mui/icons-material/Add';

// Local imports
import MainAppBar from '@/components/MainAppBar';
import OutfitCardGrid from '@/components/OutfitCardGrid';
import OutfitSelector from '@/components/OutfitSelector';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { Outfit, getOutfits, addOutfit } from '@/store/outfit';

//////////////////////////////////////////////////////////////////////////////////////////

// Component definition
function StartPage() {
  const user = React.useContext(UserStore);
  //const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [outfits, setOutfits] = React.useState<Outfit[]>([]);
  const [filteredOutfits, setFilteredOutfits] = React.useState<Outfit[]>([]);
  //const outfitsRef = useRef<Outfit[]>([]);
  //const navigate = useNavigate();

  const initOutfits = React.useCallback(async () => {
    const extResponse = await getOutfits(user.jwt);

    // error handling
    setOutfits(extResponse.outfits);
    setFilteredOutfits(extResponse.outfits);
  }, [user.jwt]);

  React.useEffect(() => {
    initOutfits();
  }, [initOutfits]);

  const handleAddOutfit = async (name: string) => {
    const response = await addOutfit(user.jwt, name);
    console.log('Add outfit: ', response);
    initOutfits();
  };

  return (
    <div>
      <MainAppBar>
        <OutfitSelector
          label='Outfit'
          options={outfits}
          size='small'
          addOutfit={handleAddOutfit}
          setFiltered={_filteredOutfits => setFilteredOutfits(_filteredOutfits)}
        />
        {/* <Tooltip title='Add'>
          <IconButton><AddIcon /></IconButton>
        </Tooltip> */}
      </MainAppBar>

      <Container sx={{ }}>
        <Box sx={{ my: 4 }}>
          <OutfitCardGrid text='TEXT' items={filteredOutfits} />
          {/* <MainContent>
            <ProjectsView/>
          </MainContent>

          <Footer>
            <Copyright />
          </Footer> */}
        </Box>
      </Container>
    </div>
  );
}

// Default export
export default StartPage;
