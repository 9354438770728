/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions, FilterOptionsState } from '@mui/base';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';

// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';

// import AddIcon from '@mui/icons-material/Add';
// import { URLSearchParams } from 'url';

// Local imports
import { Outfit } from '@/store/outfit';

// Component props
interface OutfitSelectorProps extends BaseTextFieldProps {
  label: string,
  options: Outfit[],
  addOutfit?(name: string): void,
  setFiltered?(outfits: Outfit[]): void
}

const filter = createFilterOptions<Outfit>();

// Component definition
function OutfitSelector( {label, options, addOutfit, setFiltered, ...textFieldProps}: OutfitSelectorProps ) {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState('');
  const lastInputValueRef = React.useRef<string | null>(null);

  // const handleSubmit = useCallback(
  //   async (event: React.FormEvent<HTMLFormElement>) => {
  //     event.preventDefault();

  //     console.log(event.currentTarget.outfitSelector.value);
  //   }, []);

  const handleChange = (event: unknown, value: Outfit | null) => {
    if (value)
      navigate('/outfit/'+value.id+'/'+value.name);
  };

  const handleInputChange = (event: unknown, value: string) => {
    if (value)
      setInputValue(value)
    else
      setInputValue('')
  };

  const handleAddOutfit = () => {
    // check inputValue
    if (addOutfit) {
      const response = addOutfit(inputValue);
      // console.log(response);
      // console.log(inputValue);
    }
    setInputValue('');
    // console.log('Set Filtered to empty.')
    // lastInputValueRef.current = '';
    // if (setFiltered)
    //   setFiltered(options);
  };

  const useFilterOptions = (options: Outfit[], state: FilterOptionsState<Outfit>) => {
    const filtered = filter(options, state);

    if (state.inputValue !== lastInputValueRef.current) {
      lastInputValueRef.current = state.inputValue;
      if (setFiltered)
        setFiltered(filtered);
    }
    return filtered;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Autocomplete
        // value={value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        filterOptions={useFilterOptions}
        options={options}
        getOptionLabel={(option) => option.name }
        noOptionsText={
          <Button onClick={handleAddOutfit}>Create New</Button>
          //<Button onClick={handleAddOutfit2}>Create New</Button>
        }
        renderInput={(params) => (
          <TextField {...params} {...textFieldProps} label={label}/>
        )}
        blurOnSelect
      />
    </Box>
  );
}

// Default export
export default OutfitSelector;
