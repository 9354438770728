// Modal dialog for creating a new shooting

// External imports
import React from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// Local imports

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { addOrder, getOrders } from '@/store/order';
import { Layer, Shooting } from '@/store/shooting';
import { Classes, getClasses } from '@/store/class';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface OrderDialogProps {
  shooting: Shooting;
  mode: string;
  targets: boolean[];
  layers: Layer[];
  factors: number[];
  confirmOrder: boolean;
  setConfirmOrder: React.Dispatch<React.SetStateAction<boolean>>;
}

function OrderDialog( { shooting, mode, targets, layers, factors, confirmOrder, setConfirmOrder }: OrderDialogProps ) {
  const user = React.useContext(UserStore);
  const [classes, setClasses] = React.useState<Classes>({});

  const init = React.useCallback(async () => {
    const classesResponse = await getClasses(user.jwt);
    setClasses(classesResponse.data);
  }, [user.jwt]);

  React.useEffect(() => {
    init();
  }, [init]);

  const orderedTargets = Object.entries(shooting.configuration.modes[mode].targets).filter((_, i) => targets[i]);

  const handleConfirm = async function() {
    orderedTargets.forEach(async ([targetKey, target]) => {
      const configuration : {[key: string] : number} = {}

      layers.forEach((layer, index) => configuration[layer.id] = factors[index])
      const task = await addOrder(user.jwt, shooting.id, mode, targetKey, target.class, configuration);
      console.log('Task: ', task)
    })
    setConfirmOrder(false);
  };

  return (
      <Dialog
        open={confirmOrder}
        // onClose={handleCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Order Confirmation
          {/* <DialogContentText id='alert-dialog-description'>
          </DialogContentText>
            <TextField
              margin='dense'
              size='small'
              required
              fullWidth
              id='name'
              label='Name'
              // value={name}
              // onChange={(event) => {setName(event.target.value)}}
              autoFocus
              InputProps={{
                readOnly: true
              }}
            /> */}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" component="div">
            {user.data?user.data.name:''}
          </Typography>
          <TextField
            margin='normal' size='small' fullWidth
            label='Outfit'
            value={shooting.outfit.name}
            InputProps={{ readOnly: true }}
          />
          <TextField
            margin='normal' size='small' fullWidth
            label='Shooting'
            value={shooting.name}
            InputProps={{ readOnly: true }}
          />
          <TextField
            margin='normal' size='small' fullWidth
            label='Mode'
            value={shooting.configuration.modes[mode].name}
            InputProps={{ readOnly: true }}
          />
          <TableContainer component={Paper}>
            <Table sx={{ }}>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell align='right'>Class</TableCell>
                  <TableCell align='right'>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderedTargets.map(([targetKey, target], index) => (
                  <TableRow
                    key={index}
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell size='small' component='th' scope='row'>{target.name}</TableCell>
                    <TableCell size='small' align='right'>{target.class}</TableCell>
                    <TableCell size='small' align='right'>{classes[target.class].price} €</TableCell>
                  </TableRow>
                ))}
                {/* <TableRow>
                  <TableCell colSpan={2}>Total excl. tax</TableCell>
                  <TableCell align="right">
                    {orderedTargets.reduce((total, [_, target]) => total + prices[target.class], 0)} €
                  </TableCell>
                </TableRow> */}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Total excl. tax</TableCell>
                  <TableCell align="right">
                    {orderedTargets.reduce((total, [_, target]) => total + classes[target.class].price, 0)} €
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* <TableFooter>
                <TableRow>
                  <TableCell colSpan={2}>Total excl. tax</TableCell>
                  <TableCell align="right">
                    {total} €
                  </TableCell>
                </TableRow>
              </TableFooter> */}
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOrder(false)}>Cancel</Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
  );
}

// Default export
export default OrderDialog;
