/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
import React from 'react';
import { useMeasure } from 'react-use';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { Outfit, getThumb } from '@/store/outfit';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface OutfitCardProps {
  outfit: Outfit,
}

// Component definition
function OutfitCard( { outfit }: OutfitCardProps ) {
  const user = React.useContext(UserStore);
  const navigate = useNavigate();
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const [thumbURL, setThumbURL] = React.useState<string>();
  const date = new Date(outfit.createdAt);

  const initThumb = React.useCallback(async () => {
    setThumbURL(await getThumb(user.jwt, outfit.id));
  }, [user.jwt, outfit.id]);

  React.useEffect(() => {
    initThumb();
  }, [initThumb]);

  return (
    <div ref={ref}>
      <Card sx={{ width: 1, display: 'inline' }}>
        <CardActionArea
          component="a"
          type="file"
          onClick={e => { navigate('/outfit/'+outfit.id+'/'+outfit.name); }}
        >
          <CardMedia
            component="img"
            title={outfit.name}
            alt={outfit.image}
            src={thumbURL}
            height={width}
            sx={{ objectFit: 'contain' }}
          />
          <CardContent>
            {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Word of the Day
            </Typography> */}
            <Typography variant="h5" component="div">
              {outfit.name}
            </Typography>
            {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {outfit.slug}
            </Typography> */}
            <Typography variant="body2">
              {date.toLocaleString('en-US')}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

// Default export
export default OutfitCard;
