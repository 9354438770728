// External imports
import { Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

// Local imports
import PrivateRoute from '@/components/PrivateRoute';
import LoginPage from '@/pages/LoginPage';
import OrderPage from '@/pages/OrderPage';
import OrderOverviewPage from '@/pages/OrderOverviewPage';
import OutfitPage from '@/pages/OutfitPage';
import ShootingPage from '@/pages/ShootingPage';
import StartPage from '@/pages/StartPage';
import theme from './theme';

// Component definition
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path='/' element={<PrivateRoute />}>
          <Route path='/' element={<StartPage />} />
          <Route path='/outfit/:id/:name' element={<OutfitPage />} />
          <Route path='/shooting/:id' element={<ShootingPage />} />
          <Route path='/order/:id' element={<OrderPage />} />
          <Route path='/orderOverview' element={<OrderOverviewPage />} />
        </Route>
        <Route path='/login' element={<LoginPage />} />
      </Routes>
    </ThemeProvider>
  );
}

// Default export
export default App;
