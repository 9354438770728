
export function slugify(text: string) {
  // return text
  //   .normalize('NFKD') // split an accented letter in the base letter and the acent
  //   .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
  //   .toLowerCase()
  //   .trim()
  //   .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
  //   .replace(/\s+/g, '-') // separator

  return text
    .toString()                      // Cast to string (optional)
    .normalize('NFKD')               // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()                   // Convert the string to lowercase letters
    .trim()                          // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')            // Replace spaces with -
    .replace(/[^\w-]+/g, '')         // Remove all non-word chars
    .replace(/_/g,'-')              // Replace _ with -
    .replace(/--+/g, '-')          // Replace multiple - with single -
    .replace(/-$/g, '');            // Remove trailing -
}
