import axios, { AxiosProgressEvent } from 'axios';
// import axios, { AxiosError, AxiosProgressEvent, AxiosResponse } from 'axios';

import { cmsEndpoints, CMS_URL } from '@/config/config';

import { Order } from './order';

export interface FileForUpload {
  file: File;
  progress: number;
}

export interface Layer {
  id: string;
  name: string;
  controllable: boolean;
}

export interface Shooting {
  id: number;
  createdAt: string;
  name: string;
  outfit: { name: string, slug: string };
  pose: { name: string, model: { name: string } };
  gltfFile: { uri: string, size: number};
  requiredFiles: { uri: string, size: number }[];
  preview: { state: string, message: string };
  configuration: {
    general: { [key: string]: { name: string, factor: number} },
    modes: {
      [key: string]: {
        name: string,
        option: string,
        targets: { [key: string]: { name: string, class: string, width: number, height: number } },
        configuration: { [key: string]: { name: string, factor: (number | null)} }
      }
    }
  };
  orders: { count: number } | Order[];
}

export async function getShootings(
  userJwt: string | undefined,
  outfitId: number) {
// : Promise<AxiosResponse<any, any>> {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.outfits}/${outfitId}/shootings`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
  });
  // console.log('+++++++++++++++++++++++++++++++')
  // console.log(response)
  // console.log('+++++++++++++++++++++++++++++++')
  return response;
}

// const test = {"id":49,"createdAt":"2023-05-25T08:01:41.798Z","name":"Richtig2","pose":{"id":1,"name":"641","model":{"id":1,"name":"Steffanie"}},"gltfFile":{"id":241,"uri":"230328_F1.gltf","size":"71934"},"requiredFiles":[{"id":242,"uri":"230328_F1-2-221-8542-4180_pose641_UV.bin","size":"12780136"},{"id":243,"uri":"230328_F1-2-221-8542-4180_pose641_UV_diffuse_1001.png","size":"366653"},{"id":244,"uri":"230328_F1-2-221-8542-4180_pose641_UV_normal_1001.png","size":"414174"},{"id":245,"uri":"230328_F1-2-221-8542-4180_pose641_UV_metallicroughness_1001.png","size":"148675"}],"preview":{"id":43,"state":"done","message":null}}

export async function getShooting(
  userJwt: string | undefined,
  shootingId: number) {
// : Promise<AxiosResponse<any, any>> {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.shootings}/${shootingId}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
  });
  return response.data as Shooting;
}

export async function addShooting(
  userJwt: string | undefined,
  name: string,
  outfitId: number,
  poseId: number,
  gltfFile: File,
  requiredFiles: { [uri: string] : FileForUpload | null }) {
// : Promise<AxiosResponse<any, any>> {

  const files: { uri: string, size: number }[] = [];
  Object.entries(requiredFiles).forEach(([uri, item]) => files.push( { uri: uri, size: item?item.file.size:0 } ))

  return await axios.post(`${CMS_URL}${cmsEndpoints.shootings}`,
    {
      data: {
        name: name,
        outfit: outfitId,
        pose: poseId,
        gltfFile: {
          uri: gltfFile.name,
          size: gltfFile.size,
        },
        requiredFiles: files,
        preview: {
          state: 'pending',
        },
      },
    }, {
      headers: {
        'Authorization': `Bearer ${userJwt}`,
      },
    },
  );
}

export function uploadFile(
  userJwt: string | undefined,
  shootingId: number,
  file: File,
  progress: ((progressEvent: AxiosProgressEvent) => void)) {
// : Promise<AxiosResponse<any, any>> {
  const formData = new FormData();

  //formData.append('shootingId', shootingId.toString());
  formData.append('file', file);
  return axios.post(`${CMS_URL}${cmsEndpoints.shootingsUpload.replace(':id', shootingId.toString())}`, formData, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progress,
  })
}

export async function renderPreview(userJwt: string | undefined, shootingId: number) {
  try {
    return await axios.post(`${CMS_URL}${cmsEndpoints.shootingsPreview.replace(':id', shootingId.toString())}?render`, null, {
      headers: {
        'Authorization': `Bearer ${userJwt}`,
      },
    });
  }
  catch (error) {
    if (axios.isAxiosError(error))
      return error.response;
  }
}

export async function getThumb(userJwt: string | undefined, shootingId: number, mode='auto') {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.shootingsPreview.replace(':id', shootingId.toString())}?thumb=${mode}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
    responseType: 'blob',
  })
  return URL.createObjectURL(response.data);
}

export async function getImage(userJwt: string | undefined, shootingId: number, mode: string, image: string) {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.shootingsPreview.replace(':id', shootingId.toString())}?image=${mode}_${image}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
    responseType: 'blob',
  })
  return createImageBitmap(response.data);
  // options: { premultiplyAlpha: 'none', colorSpaceConversion: 'none' }
}
