/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
//import { ConstructionOutlined } from '@mui/icons-material';
// import { useMeasure } from 'react-use';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box'
// import Button from '@mui/material/Button';
// import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';

// import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
// import ImageIcon from '@mui/icons-material/Image';
import LogoutIcon from '@mui/icons-material/Logout';
// import LoginIcon from '@mui/icons-material/Login';
import ReceiptIcon from '@mui/icons-material/Receipt';

// Local imports
//import styles from './index.module.scss';

// Component props
export interface MainAppBarProps {
  enableBack?: boolean,
  callLogout?: () => void,
  children?: JSX.Element | JSX.Element[],
}

// Component definition
function MainAppBar( { enableBack, callLogout, children } : MainAppBarProps ) {
  const navigate = useNavigate();
  //const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure<HTMLDivElement>();


  const handleClickBack = () => {
      navigate(-1);
  };

  const handleClickHome = () => {
    navigate('/');
  };

  const handleClickOrderOverview = () => {
    navigate('/orderOVerview');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        {/* sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}> */}
        <Toolbar>
          <Box
            component='img'
            sx={{ height: '50px', color: 'green' }}
            src='/BBGROUP_pos_160729_white.svg'
          />

          <Box sx={{ flexGrow: 1 }} />
          {Array.isArray(children)?
            children.map((tool) => {
              return (tool);
            })
          :
            children
          }
          <Box sx={{ flexGrow: 1 }} />

          {enableBack?
            <Tooltip title='Back'>
              <IconButton onClick={handleClickBack}><ArrowBackIcon /></IconButton>
            </Tooltip>
          :
            null
          }
          <Tooltip title='Order Overview'>
            <IconButton onClick={handleClickOrderOverview}><ReceiptIcon /></IconButton>
          </Tooltip>
          <Tooltip title='Home'>
            <IconButton onClick={handleClickHome}><HomeIcon /></IconButton>
          </Tooltip>
          {/* <IsLoggedIn reverse>
          <Tooltip title="Login">
              <IconButton onClick={() => history.push('/login')}><LoginIcon/></IconButton>
            </Tooltip>

            <Button color="inherit" onClick={() => history.push('/login')}>Login</Button>
          </IsLoggedIn> */}

          {/* <IsLoggedIn>
            <Tooltip title="Home">
              <IconButton onClick={() => history.push('/')}><HomeIcon/></IconButton>
            </Tooltip>
            <Tooltip title="Snapshots">
              <IconButton onClick={() => history.push('/snapshots')}><ImageIcon/></IconButton>
            </Tooltip> */}

            <Tooltip title='Logout'>
              {/* <IconButton onClick={() => {
              user.logout();
              history.push('/login');
              }}><LogoutIcon/></IconButton> */}
              <IconButton onClick={callLogout}><LogoutIcon/></IconButton>
            </Tooltip>

          {/* </IsLoggedIn> */}
        </Toolbar>
      </AppBar>
    </Box>
  );

}

// Default props
MainAppBar.defaultProps = {
  callLogout: () => console.log('Logout'),
};

// Default export
export default MainAppBar;
