export interface UserRoleData {
  id: number;
  name: string;
  description: string;
  type: string;
}

export interface UserData {
  id: number;
  name: string;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role?: UserRoleData;
  created_at: string;
  updated_at: string;
  maxIdleTime: number;
  snapshotsBudget: number;
  requestedSnapshots: number;
  canRequestSnapshotsrendering: boolean;
}

export class User {
  private _data?: UserData;
  private _jwt?: string;

  public constructor() {
    const userJson = sessionStorage.getItem('user');

    if (userJson != null) {
      const user = JSON.parse(userJson);
      this._data = user._data;
      this._jwt = user._jwt;
    }
  }

  public set data(value: UserData | undefined) {
    this._data = value;
  }

  public get data(): UserData | undefined {
    return this._data;
  }

  public set jwt(value: string | undefined) {
    this._jwt = value;
  }

  public get jwt(): string | undefined {
    return this._jwt;
  }

  public isLoggedIn(): boolean {
    return this._data !== undefined && this._jwt !== undefined && this._jwt !== '';
  }

  public update(userData: UserData, jwt: string) {
    this._data = userData;
    this.jwt = jwt;
    sessionStorage.setItem('user', JSON.stringify(this));
  }

  public logout() {
    this._data = undefined;
    this._jwt = undefined;
    sessionStorage.removeItem('user');
  }
}

export default User;
