/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions, FilterOptionsState } from '@mui/base';

import Box from '@mui/material/Box';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';

// Local imports
import { Shooting } from '@/store/shooting';

// Component props
interface ShootingSelectorProps extends BaseTextFieldProps {
  label: string,
  options: Shooting[],
  setFiltered?(shootings: Shooting[]): void
}

const filter = createFilterOptions<Shooting>();

// Component definition
function ShootingSelector( {label, options, setFiltered, ...textFieldProps}: ShootingSelectorProps ) {
  const [inputValue, setInputValue] = React.useState('');
  const lastInputValueRef = React.useRef<string | null>(null);

  const handleInputChange = (event: unknown, value: string) => {
    if (value)
      setInputValue(value)
    else
      setInputValue('')
  };

  const useFilterOptions=(options: Shooting[], state: FilterOptionsState<Shooting>) => {
    const filtered = filter(options, state);

    if (state.inputValue !== lastInputValueRef.current) {
      lastInputValueRef.current = state.inputValue;
      if (setFiltered)
        setFiltered(filtered);
    }
    return filtered;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Autocomplete
        // onChange={handleChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        filterOptions={useFilterOptions}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField {...params} {...textFieldProps} label={label}/>
        )}
      />
    </Box>
  );
}

// Default export
export default ShootingSelector;
