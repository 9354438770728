import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import { useMeasure } from 'react-use';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
// import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ResetIcon from '@mui/icons-material/RestartAlt';

// Local imports
import MainAppBar from '@/components/MainAppBar';
import OrderCardGrid from '@/components/OrderCardGrid';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { AlertProps } from '@/store/alert';
import { Layer, Shooting, getShooting } from '@/store/shooting';
import { Order } from '@/store/order';
import { useInterval } from '@/util/useInterval';

//////////////////////////////////////////////////////////////////////////////////////////

// Component definition
function ShootingPage() {
  const user = React.useContext(UserStore);
  const navigate = useNavigate();
  const { id, name } = useParams();
  const [alert, setAlert] = React.useState<AlertProps | null>(null);
  const [shooting, setShooting] = React.useState<Shooting>();

  const initShooting = React.useCallback(async (id: string) => {
    const shooting = await getShooting(user.jwt, Number(id));
    // console.log('--------------------------');
    // console.log(shooting);
    // console.log('--------------------------');
    setShooting(shooting);
  }, [user.jwt]);

  React.useEffect(() => {
    if (id)
      initShooting(id);
  }, [initShooting, id]);

  useInterval(async () => {
    if (id)
      initShooting(id);
  }, 10000);

  if (shooting)
    return (
      <>
        <MainAppBar enableBack>
          <TextField sx={{ flexGrow: 1 }}
            margin='dense' size='small' label='Outfit'
            value={shooting.outfit.name}
            InputProps={{ readOnly: true }}
          />
          <Box sx={{ flexGrow: 0.1 }}/>
          <TextField sx={{ flexGrow: 1 }}
            margin='dense' size='small' label='Shooting'
            value={shooting.name}
            InputProps={{ readOnly: true }}
          />
          <Box sx={{ flexGrow: 0.1 }}/>
          <Tooltip title='New Order'>
            <IconButton onClick={_ => navigate('/order/'+shooting.id)}><AddIcon /></IconButton>
          </Tooltip>
        </MainAppBar>

        <Container sx={{ }} >
          <Box
            sx={{ my: 4 }}
          >
            <Collapse in={alert?true:false}>
              <Alert
                {...alert}
                variant='outlined'
                action={
                  <IconButton
                    aria-label='close' color='inherit' size='small'
                    onClick={() => { setAlert(null); }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alert?alert.text:''}
              </Alert>
            </Collapse>
            <OrderCardGrid shooting={shooting} />
            {/* <MainContent>
              <ProjectsView/>
            </MainContent>

            <Footer>
              <Copyright />
            </Footer> */}
          </Box>
        </Container>
      </>
    );
  else
    return null;
}

// Default export
export default ShootingPage;
