
// External imports
import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField, { BaseTextFieldProps } from '@mui/material/TextField';

// Local imports
import { ModelData, PoseData } from '@/store/outfit';

//////////////////////////////////////////////////////////////////////////////////////////

import { cmsEndpoints, CMS_URL } from '@/config/config';
import { UserStore } from '@/store';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface PoseSelectorProps extends BaseTextFieldProps {
  model: ModelData | null;
  pose: PoseData | null;
  setPose: React.Dispatch<React.SetStateAction<PoseData | null>>;
}

// Component definition
function PoseSelector( {model, pose, setPose, ...textFieldProps}: PoseSelectorProps ) {
  const user = React.useContext(UserStore);
  const [poses, setPoses] = React.useState<PoseData[]>([]);

  const initPoses = React.useCallback(async (id: number) => {
    const response = await fetch(`${CMS_URL}${cmsEndpoints.models}/${id}?populate=poses&sort[0]=name`, {
      headers: {
        'Authorization': `Bearer ${user.jwt}`,
      },
    });
    if (response.status === 200) {
      const data = (await response.json());//['data'].attributes as Array<TagOutfit>;
      let _poses: PoseData[] = [];
      data.data.attributes.poses.data.forEach((item: any) => {
        // console.log(item.attributes.slug);
        _poses.push({
          id: item.id,
          slug: item.attributes.slug,
          name: item.attributes.name,
          shotAt: item.attributes.shotAt,
        });
      })
      _poses = _poses.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
      setPoses(_poses);
    }
  }, [user.jwt]);

  React.useEffect(() => {
    setPose(null);
    if (model)
      initPoses(model.id);
    else
      setPoses([]);
  }, [initPoses, setPose, model]);

  return (
    <Box>
      <Autocomplete
        disabled={model === null}
        value={pose}
        onChange={(event: React.SyntheticEvent, newValue: PoseData | null) => { setPose(newValue) }}
        options={poses}
        getOptionLabel={(option) => { return option.name; }}
        renderInput={(params) => (
            <TextField {...params} {...textFieldProps} label='Pose' variant="outlined"/>
        )}
      />
    </Box>
  );
}

// Default export
export default PoseSelector;
