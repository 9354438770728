import axios, { AxiosProgressEvent } from 'axios';
// import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';

import { cmsEndpoints, CMS_URL } from '@/config/config';

export interface Order {
  id: number;
  users_permissions_user: { name: string }
  shooting: {
    name: string,
    outfit: { name: string }
  }
  createdAt: string;
  mode: string,
  target: string,
  class: string,
  rendering: { state: string, message: string };
  providedOn: string;
}

export async function getOrders(userJwt: string | undefined, month: number, year: number) {
  const filtersLow = 'filters[providedOn][$gte]=' + year + '-' + month.toString().padStart(2, '0') + '-01';
  const filtersHigh = 'filters[providedOn][$lt]=' + year + '-' + (month+1).toString().padStart(2, '0') + '-01';
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.orders}/orders?${filtersLow}&${filtersHigh}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
  });
  // console.log('+++++++++++++++++++++++++++++++')
  // console.log(response)
  // console.log('+++++++++++++++++++++++++++++++')
  return response;
}


export async function addOrder(
  userJwt: string | undefined,
  shootingId: number,
  mode: string,
  target: string,
  type: string,
  configuration: {[key: string] : number}) {
// : Promise<AxiosResponse<any, any>> {
  return await axios.post(`${CMS_URL}${cmsEndpoints.orders}`,
    {
      data: {
        shooting: shootingId,
        mode: mode,
        target: target,
        class: type,
        configuration: configuration,
      },
    }, {
      headers: {
        'Authorization': `Bearer ${userJwt}`,
      },
    },
  );
}

// export async function uploadFile(
//   userJwt: string | undefined,
//   shootingId: number,
//   file: File,
//   progress: ((progressEvent: AxiosProgressEvent) => void)) {
// // : Promise<AxiosResponse<any, any>> {
//   const formData = new FormData();

//   //formData.append('shootingId', shootingId.toString());
//   formData.append('file', file);
//   return await axios.post(`${CMS_URL}${cmsEndpoints.shootingsUpload.replace(':id', shootingId.toString())}`, formData, {
//     headers: {
//       'Authorization': `Bearer ${userJwt}`,
//       'Content-Type': 'multipart/form-data',
//     },
//     onUploadProgress: progress,
//   })
// }

export async function getDelivery(
  userJwt: string | undefined,
  orderId: number,
  progress: ((progressEvent: AxiosProgressEvent) => void)) {

  const response = await axios.get(`${CMS_URL}${cmsEndpoints.ordersDelivery.replace(':id', orderId.toString())}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
    responseType: 'blob',
    onDownloadProgress: progress,
  })
  return [URL.createObjectURL(response.data), response.data.type.slice(-3)];
}

export async function getThumb(userJwt: string | undefined, orderId: number) {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.ordersThumb.replace(':id', orderId.toString())}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
    responseType: 'blob',
  })
  return URL.createObjectURL(response.data);
}

// export async function getImage(userJwt: string | undefined, shootingId: number, image: string) {
//   const response = await axios.get(`${CMS_URL}${cmsEndpoints.shootingsPreview.replace(':id', shootingId.toString())}?image=${image}`, {
//     headers: {
//       'Authorization': `Bearer ${userJwt}`,
//     },
//     responseType: 'blob',
//   })
//   return createImageBitmap(response.data);
//   // options: { premultiplyAlpha: 'none', colorSpaceConversion: 'none' }
// }
