// External imports
import React from 'react';
import { Document, Font, Image, Link, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { Order } from '@/store/order';
import { Classes } from '@/store/class';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface OrderOverviewPDFProps {
  month: number,
  year: number,
  orders: Order[],
  classes: Classes,
}

const mmFactor = 1 / 25.4 * 72;
const pageHeight = 257;
const tSideX = (-pageHeight / 2 + 10) * mmFactor;
const tSideY = (297 / 2) * mmFactor - 7;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF',
  },
  pageBody: {
    width: '170mm',
    margin: '20mm',
  },
  centerView: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header1View: {
    fontFamily: 'CormorantBold',
    fontSize: 27,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header2View: {
    fontFamily: 'CormorantBold',
    fontSize: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tableHeaderView: {
    height: '10mm',
    backgroundColor: '#009EE3',
    color: '#FFF',
    textAlign: 'center',
  },
  tableRowView: {
    height: '10mm',
    borderBottom: '1px solid #000',
  },
  tableRowText: {
    width: '150mm',
    textAlign: 'left',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  rowView: {
    display: 'flex', flexDirection: 'row', borderTop: '1px solid #EEE', paddingTop: 8, paddingBottom: 8, textAlign: 'center',
  },
});

Font.register({ family: 'Cormorant', src: '/Winterhalter/Cormorant/static/Cormorant-Regular.ttf' });
Font.register({ family: 'CormorantBold', src: '/Winterhalter/Cormorant/static/Cormorant-Bold.ttf' });

// Component definition
function OrderOverviewPDF( { month, year, orders, classes }: OrderOverviewPDFProps ) {
  const user = React.useContext(UserStore);

  return (
    <Document
      author='Meyle+Müller'
      title='Betty Barclay Order Overview'
      subject='SUBJECT'
    >
      <Page size='A4' style={{
        fontFamily: 'Cormorant',
        fontSize: 12,
      }}>
        {/* logo */}
        <Image src='/Winterhalter/Winterhalter-Logo-sRGB-300dpi_picture_INT.png' style={{ position: 'absolute', marginLeft: '162mm', width: '48mm' }} />
        {/* sidebar text */}
        <Text style={{ backgroundColor: '#EEE', fontSize: 10, transform: `translateX(${tSideX}) translateY(${tSideY}) rotate(-90deg)`, position: 'absolute', width: '257mm', height: 14 }}>
          [Kundenname] [Datum (Jahr-Monat-Tag)] [Materialnummer] [interner Konfigurationsschlüssel]
        </Text>
        {/* footer */}
        <View style={{ backgroundColor: '#EEE', fontSize: 10, position: 'absolute', marginLeft: '20mm', marginTop: '277mm', width: '170mm', height: '20mm' }}>
          <Text style={{ flex: 1 }} />
          <Text>Winterhalter Deutschland GmbH | Gewerbliche Spülsysteme</Text>
          <Text>Winterhalterstraße 2 – 12 | 88074 Meckenbeuren | Deutschland | www.winterhalter.de</Text>
          <Text style={{ flex: 1 }} />
        </View>

        <View style={styles.pageBody}>
          <View style={styles.header1View}>
            <Text>IHRE NEUE MASCHINE WARTET AUF SIE</Text>
          </View>

          <Image src='/Winterhalter/WinterhalterExample.jpg' />

          <View style={styles.header2View}>
              <Text>MASCHINENDATEN UND ABMESSUNGEN</Text>
          </View>
          <View style={styles.header2View}>
            <Text>AUF EINEN BLICK</Text>
          </View>

          <View style={styles.tableHeaderView}>
            <Text style={{ flex: 1 }} />
            <Text>Korbtransportspülmaschine MTR L35-MM</Text>
            <Text style={{ flex: 1 }} />
          </View>
          <View style={styles.tableRowView}>
            <View style={{ flex: 1 }} />
            <View style={{ flexDirection: 'row', marginHorizontal: '10mm' }}>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Körbe/h</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>3456 / 1234 / 55565</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Tankanzahl</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>4</Text>
            </View>
            <View style={{ flex: 1 }} />
          </View>
          <View style={styles.tableRowView}>
            <View style={{ flex: 1 }} />
            <View style={{ flexDirection: 'row', marginHorizontal: '10mm' }}>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Maschinenlänge</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>222 m</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Maschinenhöhe</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>2234 m</Text>
            </View>
            <View style={{ flex: 1 }} />
          </View>
          <View style={styles.tableRowView}>
            <View style={{ flex: 1 }} />
            <View style={{ flexDirection: 'row', marginHorizontal: '10mm' }}>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Maschinentiefe</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>22 m</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Einfahrbreite</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>34 m</Text>
            </View>
            <View style={{ flex: 1 }} />
          </View>
          <View style={styles.tableRowView}>
            <View style={{ flex: 1 }} />
            <View style={{ flexDirection: 'row', marginHorizontal: '10mm' }}>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Einfahrhöhe</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>12345 m</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>Arbeitshöhe</Text>
              <Text style={ [styles.tableRowText, { width: '25%' }] }>1,2 m</Text>
            </View>
            <View style={{ flex: 1 }} />
          </View>
          <View style={styles.tableRowView}>
            <View style={{ flex: 1 }} />
            <View style={{ flexDirection: 'row', marginHorizontal: '10mm' }}>
              <Text style={ [styles.tableRowText, { width: '35%' }] }>Ausgewählte Optionen</Text>
              <Text style={ [styles.tableRowText, { width: '65%' }] }>SPI, Wärmepumpe, Schlauchpendelbrause, Schlauchaufroller</Text>
            </View>
            <View style={{ flex: 1 }} />
          </View>

          <View style={styles.header2View}>
            <Text>ALLE INFOS ÜBER UNSERE</Text>
          </View>
          <View style={styles.header2View}>
            <Text>[KORBTRANSPORTSPÜLMASCHINEN]</Text>
          </View>

          <View style={styles.centerView}>
            <Image src='/Winterhalter/QR-Code-mtr.png' style={{ width: '20mm' }} />
          </View>

          <View style={styles.centerView}>
            <Link src='www.winterhalter.de/mtr' style={{ backgroundColor: '#888' }}>www.winterhalter.de/mtr</Link>
          </View>

          <View style={styles.centerView}>
            <Text style={{ backgroundColor: '#888' }}>Sollten Sie Fragen oder Anregungen haben, können Sie uns gerne kontaktieren. Wir freuen uns!</Text>
          </View>

          <View style={{ flexDirection: 'row', marginHorizontal: '10mm', height: '10mm' }}>
            <Image src='/Winterhalter/Anwender_blue_RGB.png' style={{ objectFit: 'contain' }} />
            <View>
              <Text style={{ flex:1 }} />
              <Text style={{ padding: '2mm' }}>Ansprechpartner</Text>
              <Text style={{ flex:1 }} />
            </View>
            <View style={{ flex:1 }} />
            <Image src='/Winterhalter/Anwender_blue_RGB.png' style={{ objectFit: 'contain' }} />
            <View style={{ flexDirection: 'column' }}>
              <Text style={{ flex:1 }} />
              <Text style={{ padding: '2mm' }}>+49 7542 402-1240</Text>
              <Text style={{ flex:1 }} />
            </View>
            <View style={{ flex:1 }} />
            <Image src='/Winterhalter/Mail_blue_RGB.png' style={{ objectFit: 'contain' }} />
            <View>
              <Text style={{ flex:1 }} />
              <Text style={{ padding: '2mm' }}>willy.meier@winterhalter.com</Text>
              <Text style={{ flex:1 }} />
            </View>
          </View>

        </View>
      </Page>
    </Document>
  );
  // return (
  //   <Document
  //     author='Meyle+Müller'
  //     title='Betty Barclay Order Overview'
  //     subject='SUBJECT'
  //   >
  //     <Page size="A4" style={styles.page}>
  //       <View style={styles.section}>
  //         <Text>Month: {month}</Text>
  //       </View>
  //       <View style={styles.section}>
  //         <Text>Year: {year}</Text>
  //       </View>
  //       <View style={styles.rowView}>
  //         <Text style={{ width:'10%' }}>Id</Text>
  //         <Text style={{ width:'10%' }}>User</Text>
  //         <Text style={{ width:'10%' }}>Outfit</Text>
  //         <Text style={{ width:'10%' }}>Shooting</Text>
  //         <Text style={{ width:'20%' }}>Provision</Text>
  //         <Text style={{ width:'10%' }}>Class</Text>
  //         <Text style={{ width:'10%' }}>Price</Text>
  //       </View>
  //       {orders.map((order, index) => (
  //         <View key={index} style={styles.rowView}>
  //           <Text style={{ width:'10%' }}>{order.id}</Text>
  //           <Text style={{ width:'10%' }}>{order.users_permissions_user.name}</Text>
  //           <Text style={{ width:'10%' }}>{order.shooting.outfit.name}</Text>
  //           <Text style={{ width:'10%' }}>{order.shooting.name}</Text>
  //           <Text style={{ width:'20%' }}>{new Date(order.providedOn).toLocaleString('en-US')}</Text>
  //           <Text style={{ width:'10%' }}>{order.class}</Text>
  //           <Text style={{ width:'10%' }}>{classes[order.class].price} €</Text>
  //         </View>
  //       ))}
  //       <View style={styles.rowView}>
  //         <Text style={{ width:'70%' }}>Total excl. tax</Text>
  //         <Text style={{ width:'10%' }}>{orders.reduce((total, order) => total + classes[order.class].price, 0)} €</Text>
  //       </View>
  //       <View style={styles.rowView} />
  //     </Page>
  //   </Document>
  // );
}

// Default export
export default OrderOverviewPDF;
