import axios from 'axios';
// import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';

import { cmsEndpoints, CMS_URL } from '@/config/config';

export interface Class {
  name: string;
  price: number;
}

export interface Classes {
  [name: string] : {
    price: number;
  }
}

export async function getClasses(userJwt: string | undefined) {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.classes}`, {
    // ?filters[providedOn][$lte]=2023-06-21
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
  });
  // console.log('+++++++++++++++++++++++++++++++')
  // console.log(response)
  // console.log('+++++++++++++++++++++++++++++++')
  return response;
}
