import React from 'react';
// import { useMeasure } from 'react-use';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
// import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

// import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
// import ResetIcon from '@mui/icons-material/RestartAlt';

import { PDFDownloadLink } from '@react-pdf/renderer';

// Local imports
import MainAppBar from '@/components/MainAppBar';
import OrderOverviewPDF from '@/components/OrderOverviewPDF';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { AlertProps } from '@/store/alert';
import { Order, getOrders } from '@/store/order';
import { Classes, getClasses } from '@/store/class';

//////////////////////////////////////////////////////////////////////////////////////////

// Component definition
function OrderOverviewPage() {
  const startYear = 2023;
  const today = new Date();
  const user = React.useContext(UserStore);
  const [alert, setAlert] = React.useState<AlertProps | null>(null);
  const [classes, setClasses] = React.useState<Classes>({});
  const [month, setMonth] = React.useState(today.getMonth()+1);
  const [year, setYear] = React.useState(today.getFullYear());
  const [orders, setOrders] = React.useState<Order[]>([]);

  // const modeIndex = shooting?shooting.configuration.modes.map(mode => mode.option).indexOf(mode):0;

  const init = React.useCallback(async () => {
    const classesResponse = await getClasses(user.jwt);
    setClasses(classesResponse.data);
    const ordersResponse = await getOrders(user.jwt, month, year);
    // console.log('--------------------------');
    // console.log(ordersResponse);
    // console.log('--------------------------');
    setOrders(ordersResponse.data);
  }, [user.jwt, month, year]);

  React.useEffect(() => {
    init();
  }, [init]);

  const pdf = React.useMemo(() => (
    <OrderOverviewPDF
      month={month}
      year={year}
      classes={classes}
      orders={orders} />
  ), [month, year, classes, orders]);

  if (orders) {
    return (
      <>
        <MainAppBar enableBack>
          <Typography component='div' variant='h5'>
            Order Overview
          </Typography>
        </MainAppBar>

        <Container sx={{ }} >
          <Box
            sx={{ my: 4 }}
          >
            <Collapse in={alert?true:false}>
              <Alert
                {...alert}
                variant='outlined'
                action={
                  <IconButton
                    aria-label='close' color='inherit' size='small'
                    onClick={() => { setAlert(null); }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alert?alert.text:''}
              </Alert>
            </Collapse>

            <TextField
              sx={{ m: 1, minWidth: 130 }}
              select
              value={month.toString()}
              label='Month'
              size='small'
              onChange={(event) => {setMonth(Number(event.target.value))}}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>
            </TextField>

            <TextField
              sx={{ m: 1, minWidth: 130 }}
              select
              value={year.toString()}
              label='Year'
              size='small'
              onChange={(event) => {setYear(Number(event.target.value))}}
            >
              {Array.from(Array(today.getFullYear()-startYear+1).keys()).map(
                (item: number) => <MenuItem key={item} value={item+startYear}>{item+startYear}</MenuItem>)}
            </TextField>

            <TableContainer component={Paper}>
              <Table sx={{ }}>
                <TableHead>
                  <TableRow>
                    <TableCell align='right'>Id</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Outfit</TableCell>
                    <TableCell>Shooting</TableCell>
                    <TableCell>Provision</TableCell>
                    <TableCell align='right'>Class</TableCell>
                    <TableCell align='right'>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order, index) => (
                    <TableRow
                      key={index}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell size='small' align='right'>{order.id}</TableCell>
                      <TableCell size='small'>{order.users_permissions_user.name}</TableCell>
                      <TableCell size='small'>{order.shooting.outfit.name}</TableCell>
                      <TableCell size='small'>{order.shooting.name}</TableCell>
                      <TableCell size='small'>{new Date(order.providedOn).toLocaleString('en-US')}</TableCell>
                      <TableCell size='small' align='right'>{order.class}</TableCell>
                      <TableCell size='small' align='right'>{classes[order.class].price} €</TableCell>
                    </TableRow>
                  ))}
                  {/* <TableRow>
                    <TableCell colSpan={2}>Total excl. tax</TableCell>
                    <TableCell align="right">
                      {orderedTargets.reduce((total, [_, target]) => total + prices[target.class], 0)} €
                    </TableCell>
                  </TableRow> */}
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6}>Total excl. tax</TableCell>
                    <TableCell align="right">
                      {orders.reduce((total, order) => total + classes[order.class].price, 0)} €
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* <TableFooter>
                  <TableRow>
                    <TableCell colSpan={2}>Total excl. tax</TableCell>
                    <TableCell align="right">
                      {total} €
                    </TableCell>
                  </TableRow>
                </TableFooter> */}
              </Table>
            </TableContainer>
            {/* <MainContent>
              <ProjectsView/>
            </MainContent>

            <Footer>
              <Copyright />
            </Footer> */}
            {/* <OrderOverviewPDF
              month={month}
              year={year}
              classes={classes}
              orders={orders} /> */}
            {/* <PDFDownloadLink document={pdf} fileName={'orderOverview.pdf'}>
              <Button className='px-4'>Download</Button>
            </PDFDownloadLink> */}
          </Box>
        </Container>
      </>
    );
  } else
    return null;
}

// Default export
export default OrderOverviewPage;
