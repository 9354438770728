/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
import React from 'react';
import { useMeasure } from 'react-use';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
// import CircularProgress from '@mui/material/CircularProgress';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { Shooting, getThumb } from '@/store/shooting';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface ShootingCardProps {
  shooting: Shooting,
}

// Component definition
function ShootingCard( { shooting }: ShootingCardProps ) {
  const user = React.useContext(UserStore);
  const [thumbURL, setThumbURL] = React.useState<string>();
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const navigate = useNavigate();
  //const { t } = useTranslation();

  const initThumb = React.useCallback(async () => {
    setThumbURL(await getThumb(user.jwt, shooting.id));
  }, [user.jwt, shooting.id]);

  React.useEffect(() => {
    if (shooting.preview.state === 'done')
      initThumb();
  }, [initThumb, shooting.preview.state]);

  const date = new Date(shooting.createdAt)
  // console.log(shooting.configuration)
  // console.log('ORDERS: '+(shooting.orders as {count: number}).count);
  return (
    <Card ref={ref}>
      <CardActionArea
        disabled={shooting.preview.state !== 'done'}
        onClick={e => { (shooting.orders as {count: number}).count > 0 ? navigate('/shooting/'+shooting.id) : navigate('/order/'+shooting.id)}}
        sx={{ width: '384px', height: '128px', display: 'flex', alignItems: 'flex-start' }}
      >
        <Box display='flex' justifyContent='center' alignItems='center' sx={{ width: '128px', height: '128px'}}>
          {shooting.preview.state === 'pending' ?
            <Typography color='#ffeb3b'>
              Pending
            </Typography>
          : shooting.preview.state === 'processing' ?
            <Typography color='#4caf50'>
              Processing
              {/* <CircularProgress /> */}
            </Typography>
          : shooting.preview.state === 'done' ?
            <CardMedia
              component='img'
              title={shooting.name}
              alt={shooting.name}
              src={thumbURL}
              height={height}
              sx={{ flex: '0 0 128px', objectFit: 'contain' }}
            />
          :
            <Typography color='#d50000'>
              Failed
            </Typography>
          }
        </Box>
        <CardContent sx={{ flex: '1 0', pt: 0.5, pb: 0.5}}>
          <Typography component='div' variant='h6'>
            {shooting.name}
          </Typography>
          <Typography sx={{ fontSize: 10 }} color='text.secondary' gutterBottom>
            {date.toLocaleString('en-US')}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color='text.secondary'>
            Model: {shooting.pose?shooting.pose.model.name:'N/A'}<br/>
            Pose: {shooting.pose?shooting.pose.name:'N/A'}<br/>
            Orders: {(shooting.orders as {count: number}).count}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
  // return (
  //   <div ref={ref}>
  //     <Card sx={{ width: 1, display: 'inline' }}>
  //       <CardActionArea component='a' type='file'>
  //         <CardMedia
  //           component='img'
  //           title={shooting.name}
  //           alt={shooting.name}
  //           src={thumbUrl}
  //           height={width}
  //           sx={{ objectFit: 'contain' }} />
  //         <CardContent>
  //           <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
  //             {shooting.createdAt}
  //           </Typography>
  //           <Typography variant='h5' component='div'>
  //             {shooting.name}
  //           </Typography>
  //           <Typography sx={{ mb: 1.5 }} color='text.secondary'>
  //             {shooting.model?shooting.model.name:'N/A'}<br/>
  //             {shooting.pose?shooting.pose.name:'N/A'}
  //           </Typography>
  //           <Typography noWrap variant="body2">
  //             {shooting.gltfFile?shooting.gltfFile.uri:'N/A'}
  //           </Typography>
  //           {/* {shooting.requiredFiles.map((item, index) => {
  //             return(
  //               <Typography noWrap key={index} variant="body2">
  //                 {item.uri}
  //               </Typography>
  //             )
  //           })} */}
  //         </CardContent>
  //       </CardActionArea>
  //     </Card>
  //   </div>
  // );
}

// Default export
export default ShootingCard;
