import React from 'react';
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
// import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

// Local imports
import MainAppBar from '@/components/MainAppBar';
import ShootingCardGrid from '@/components/ShootingCardGrid';
import ShootingCreateDialog from '@/dialogs/ShootingCreateDialog';
import ShootingSelector from '@/components/ShootingSelector';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { AlertProps } from '@/store/alert';
import { Shooting, getShootings } from '@/store/shooting';
import { useInterval } from '@/util/useInterval';

//////////////////////////////////////////////////////////////////////////////////////////

// Component definition
function OutfitPage() {
  const user = React.useContext(UserStore);
  const { id, name } = useParams();
  const [shootings, setShootings] = React.useState<Shooting[]>([]);
  const [filteredShootings, setFilteredShootings] = React.useState<Shooting[]>([]);
  const [alert, setAlert] = React.useState<AlertProps | null>(null);
  const [gltfFile, setGltfFile] = React.useState<File | null>(null);

  const initShootings = React.useCallback(async (id: string) => {
    const response = await getShootings(user.jwt, Number(id));
    // console.log('--------------------------');
    // console.log(response);
    // console.log('--------------------------');
    setShootings(response.data);
    setFilteredShootings(response.data);
  }, [user.jwt]);

  React.useEffect(() => {
    if (id)
      initShootings(id);
  }, [initShootings, id]);

  useInterval(async () => {
    if (id)
      initShootings(id);
  }, 10000);

  const handleDrag = function(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (event.type === 'dragenter' || event.type === 'dragover') {
      if (event.dataTransfer.items.length !== 1)
        setAlert( { severity: 'info', text: 'Exactly one file required.' } );
      else {
        const { kind } = event.dataTransfer.items[0];

        if (kind !== 'file')
          setAlert( { severity: 'info', text: 'File required.' } );
        else
          // ???
          setAlert( { severity: 'info', text: 'Create new shootings' } );
      }
    } else if (event.type === 'dragleave')
      setAlert(null);
  };

  const handleDrop = function(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files.length === 1) {
      const file = event.dataTransfer.files[0];

      if (file.name.substring(file.name.lastIndexOf('.')).toLowerCase() === '.gltf') {
        setAlert( { severity: 'success', text:file.name+' has size '+file.size } );
        setGltfFile(file);
      }
      else
        setAlert( { severity: 'error', text: 'GLTF file required.' } );
    } else
      setAlert( { severity: 'error', text:'Exactly one GLTF file is required.' } );
  };

  return (
    <React.Fragment>
      <ShootingCreateDialog
        outfitId={id}
        gltfFile={gltfFile}
        setGltfFile={setGltfFile}
        setAlert={setAlert}
      />
      <div
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <MainAppBar enableBack>
          <TextField
            margin='dense' size='small' label='Outfit'
            value={name}
            InputProps={{ readOnly: true }}
          />
          {/* <Typography component='div' variant='h5'>
            {name}
          </Typography> */}
          <Box sx={{ flexGrow: 0.1 }} />
          <ShootingSelector sx={{ flexGrow: 1 }}
            margin='dense' size='small' label='Shootings'
            options={shootings}
            setFiltered={setFilteredShootings}
          />
          {/* <Tooltip title='Add'>
            <IconButton><AddIcon /></IconButton>
          </Tooltip> */}

        </MainAppBar>

        <Container sx={{ }} >
          <Box
            sx={{ my: 4 }}
          >
            <Collapse in={alert?true:false}>
              <Alert
                {...alert}
                variant='outlined'
                action={
                  <IconButton
                    aria-label='close' color='inherit' size='small'
                    onClick={() => { setAlert(null); }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alert?alert.text:''}
              </Alert>
            </Collapse>
            <ShootingCardGrid text='TEXT' items={filteredShootings} />
            {/* <MainContent>
              <ProjectsView/>
            </MainContent>

            <Footer>
              <Copyright />
            </Footer> */}
          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
}

// Default export
export default OutfitPage;
