/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
//import { ConstructionOutlined } from '@mui/icons-material';
// import { useMeasure } from 'react-use';
import Grid from '@mui/material/Unstable_Grid2';

// import Paper from '@mui/material/Paper';
// import Stack from '@mui/material/Stack';

// Local imports
import ShootingCard from '@/components/ShootingCard';

//////////////////////////////////////////////////////////////////////////////////////////

import { Shooting } from '@/store/shooting';

//////////////////////////////////////////////////////////////////////////////////////////

export interface ShootingCardGridProps {
  text: string,
  items: Shooting[],
}

function ShootingCardGrid( { text, items }: ShootingCardGridProps ) {
  // const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Grid container spacing={2}>
      {items.map((item, index) => {
        return (
          <Grid key={index}>
            <ShootingCard shooting={item} />
          </Grid>
        )
      })}
    </Grid>
  );
}

// Default export
export default ShootingCardGrid;
