// Local imports
import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import LoadingButton from '@mui/lab/LoadingButton';

//////////////////////////////////////////////////////////////////////////////////////////

import { cmsEndpoints, CMS_URL } from '@/config/config';
import { UserStore } from '@/store';
import { UserData } from '@/store/user';

//////////////////////////////////////////////////////////////////////////////////////////

interface LoginResponse {
  jwt: string;
  user: UserData;
  status: number;
  error: Error;
}

async function login(identifier: string, password: string, authCode?: string): Promise<LoginResponse> {
  const formData = new FormData();
  formData.append('identifier', identifier);
  formData.append('password', password);
  if (authCode) {
    formData.append('authCode', authCode);
  }

  return fetch(`${CMS_URL}${cmsEndpoints.auth}`, {
    method: 'POST',
    body: formData,
  }).then(async (r) => {
    const response = await r.json()

    return { status: r.status, ...response };
  });
}

//////////////////////////////////////////////////////////////////////////////////////////

// Component definition
function LoginPage() {
  const user = useContext(UserStore);
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loginFailMessage, setLoginFailMessage] = useState('');
  const [loginLoading, setLoginLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      setLoginLoading(true);
      setLoginFailMessage('');
      event.preventDefault();

      const data = new FormData(event.currentTarget);
      //setLoginFailMessage("");
      try {
        const id = data.get('username')!.toString();
        const pw = data.get('password')!.toString();

        const response = await login(id, pw);
        if (response.status === 200) {
          console.log(response.user, response.jwt);
          user.update(response.user, response.jwt);
          navigate('/');
        } else
          setLoginFailMessage(response.error.message)
      } catch (error: unknown) {
        if (error instanceof Error)
          setLoginFailMessage(error.toString());
        else
          setLoginFailMessage('Unknown error.');
      }
      setLoginLoading(false);
    }, [user, navigate]);

  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh'>
      <Container maxWidth='sm'>
        <Stack>
          <Box
            component='img'
            src='/BBGROUP_pos_160729_white.svg'
          />
          <Box component='form' onSubmit={handleSubmit}>
            {/* sx={{ width: '50vh' }} */}
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
              autoFocus
            />
            <TextField
              margin='normal'
              required
              fullWidth
              id='password'
              label='Password'
              type='password'
              name='password'
              autoComplete='current-password'
            />
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              loading={loginLoading}
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
            {loginFailMessage !== '' && (
              <Grow in={loginFailMessage !== ''}>
                <Box sx={{ display: 'flex' }}>
                  <Alert
                    sx={{ mb: 2 }}
                    variant="outlined"
                    severity="error"
                  >
                    {loginFailMessage}
                  </Alert>
                </Box>
              </Grow>
              )}
          </Box>
        </Stack>
      </Container>
    </Box>
   );
 }

// Default export
export default LoginPage;
