// Modal dialog for creating a new shooting

// External imports
import React from 'react';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField'

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { AlertProps } from '@/store/alert';

//////////////////////////////////////////////////////////////////////////////////////////

// Local imports
import { ModelData, PoseData } from '@/store/outfit';
import { addShooting, renderPreview, uploadFile, FileForUpload } from '@/store/shooting';
import ModelSelector from '@/components/ModelSelector';
import PoseSelector from '@/components/PoseSelector';

// Component props
interface ShootingCreateDialogProps {
  outfitId: string | undefined;
  gltfFile: File | null;
  setGltfFile: React.Dispatch<React.SetStateAction<File | null>>;
  setAlert: React.Dispatch<React.SetStateAction<AlertProps | null>>;
}

function ShootingCreateDialog( { outfitId, gltfFile, setGltfFile, setAlert }: ShootingCreateDialogProps ) {
  const user = React.useContext(UserStore);
  const [name, setName] = React.useState<string>(gltfFile?gltfFile.name.substring(0, gltfFile.name.length-5):'');
  const [model, setModel] = React.useState<ModelData | null>(null);
  const [pose, setPose] = React.useState<PoseData | null>(null);
  const [requiredFiles, setRequiredFiles] = React.useState<{ [uri: string] : FileForUpload | null }>({});
  const [uploading, setUploading] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onload = function(event) {
      if (event.target && typeof event.target.result === 'string') {
        const gltf = JSON.parse(event.target.result);
        const _requiredFiles: { [uri: string] : FileForUpload | null } = {};

        if ('buffers' in gltf)
          gltf.buffers.forEach((item: any) => {
            _requiredFiles[item.uri] = null;
          })
        if ('images' in gltf)
          gltf.images.forEach((item: any) => {
            _requiredFiles[item.uri] = null;
          })
        setRequiredFiles(_requiredFiles);
      }
    };

    if (gltfFile)
      reader.readAsText(gltfFile);
  }, [gltfFile]);

  const ready = function() : boolean {
    return name !== '' && model !== null && pose !== null && !Object.values(requiredFiles).some((file) => file === null);
  }

  const handleDrag = function(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = function(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    const _requiredFiles = {...requiredFiles};

    Array.from(event.dataTransfer.files).forEach((file) => {
      if (file.name in requiredFiles)
        _requiredFiles[file.name] = { file: file, progress: 0 };
      else
        console.log(file.name+' is not required.');
    })
    setRequiredFiles(_requiredFiles);
   };

  const handleCancel = function() {
    setRequiredFiles({});
    setModel(null);
    setPose(null);
    setGltfFile(null);
  };

  const handleUpload = React.useCallback(
    async () => {
      if (outfitId && model && pose && gltfFile && !Object.values(requiredFiles).some((item) => item === null)) {
        setUploading(true);
        const response = await addShooting(user.jwt, name, Number(outfitId), pose.id, gltfFile, requiredFiles);

        if (response.status === 200) {// && 'data' in response) {
          console.log('-------------------------');
          console.log(response);
          console.log('-------------------------');
          const shootingId = response.data.data.id;
          const gltfPromise = uploadFile(user.jwt, shootingId, gltfFile, (e) => { console.log('PROGRESS: ', e)});
          const requiredFilesPromises: ReturnType<typeof uploadFile>[] = [];

          Object.entries(requiredFiles).forEach(([uri, item]) => {
            if (item) {
              requiredFilesPromises.push(uploadFile(user.jwt, shootingId, item.file, (e) => {
                setRequiredFiles(previousRequiredFiles => {
                  const _requiredFiles = {...previousRequiredFiles};

                  if (_requiredFiles[uri])
                    _requiredFiles[uri]!.progress = e.progress?e.progress:0;
                  return _requiredFiles;
                })
              }))
            }
          })
          const gltfResponse = await gltfPromise;
          console.log('GLTF: ', gltfResponse)
          for (const promise of requiredFilesPromises) {
            const response = await promise;
            console.log('Response: ', response)
          }

          const renderResponse = await renderPreview(user.jwt, shootingId);
          if (setAlert) {
            if (renderResponse === undefined)
              setAlert( { severity: 'error', text: 'Undefined error occurred.' } );
            else if (renderResponse.status !== 200)
              setAlert( { severity: 'error', text: renderResponse.statusText } );
          }
          //console.log('Task: ', task)
        } else {
          console.log('--------- Error ---------');
          console.log(response);
          console.log('-------------------------');
        }
        setRequiredFiles({});
        setModel(null);
        setPose(null);
        setGltfFile(null);
        setUploading(false);
      }
  }, [user.jwt, name, outfitId, model, pose, gltfFile, setGltfFile, requiredFiles, setAlert]);

  return (
      <Dialog
        open={gltfFile !== null}
        onClose={handleCancel}
        scroll={scroll}
        fullWidth
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Create New Shooting
          <DialogContentText id='alert-dialog-description'>
            {gltfFile?gltfFile.name:'NULL'} <br />
            {/* {gltfFile?gltfFile.size:0} <br /> */}
          </DialogContentText>
          <TextField
            margin='dense'
            size='small'
            required
            fullWidth
            id='name'
            label='Name'
            value={name}
            onChange={(event) => {setName(event.target.value)}}
            autoFocus
          />
          <ModelSelector
            margin='dense'
            size='small'
            required
            model={model}
            setModel={setModel}
          />
          <PoseSelector
            margin='dense'
            size='small'
            required
            model={model}
            pose={pose}
            setPose={setPose}
          />
        </DialogTitle>
        <DialogContent
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          dividers={scroll === 'paper'}
        >
          Required Files:
          {Object.keys(requiredFiles).map((uri, index) => {
            console.log('Uploading: ', uploading);
            const item = requiredFiles[uri];

            if (uploading)
              return (
                <LinearProgress
                  key={index}
                  variant='determinate'
                  value={item ? 100*item.progress : 0}
                  sx={{ mb: 2, p: 0.6 }}
                />
              );
            else
              return (
                <Alert
                  key={index}
                  variant='outlined'
                  severity={item ? 'success' : 'error'}
                  sx={{ mb: 2 }}
                >
                  {uri}
                </Alert>
              )
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleUpload} disabled={!ready() || uploading}>Upload</Button>
        </DialogActions>
      </Dialog>
  );
}

/*********************************************************
<Box component='form' onSubmit={handleSubmit}>
<TextField
  margin='normal'
  required
  fullWidth
  id='username'
  label='Username'
  name='username'
  autoComplete='username'
  autoFocus
/>
<TextField
  margin='normal'
  required
  fullWidth
  id='password'
  label='Password'
  type='password'
  name='password'
  autoComplete='current-password'
/>
<LoadingButton
  type='submit'
  fullWidth
  variant='contained'
  loading={loginLoading}
  sx={{ mt: 3, mb: 2 }}
>
  Sign In
</LoadingButton>
{loginFailMessage !== '' && (
  <Grow in={loginFailMessage !== ''}>
    <Box sx={{ display: 'flex' }}>
      <Alert
        sx={{ mb: 2 }}
        variant="outlined"
        severity="error"
      >
        {loginFailMessage}
      </Alert>
    </Box>
  </Grow>
  )}
</Box>
*********************************************************/

// Default export
export default ShootingCreateDialog;
