/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
import React from 'react';
import { useMeasure } from 'react-use';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

// import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LoadingButton from '@mui/lab/LoadingButton';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { Shooting } from '@/store/shooting';
import { Order, getDelivery, getThumb } from '@/store/order';

import { slugify } from '@/util/slugify';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface OrderCardProps {
  shooting: Shooting,
  order: Order,
}

const commonStyles = {
  bgcolor: 'background.paper',
  borderColor: 'text.primary',
  m: 1,
  border: 1,
};

// Component definition
function OrderCard( { shooting, order }: OrderCardProps ) {
  const user = React.useContext(UserStore);
  const [thumbURL, setThumbURL] = React.useState<string>();
  const [downloading, setDownloading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const navigate = useNavigate();
  //const { t } = useTranslation();

  const dateCreated = new Date(order.createdAt)
  const dateProvided = new Date(order.providedOn)
  const mode = shooting.configuration.modes[order.mode];

  const initThumb = React.useCallback(async () => {
    // const thumbURL = await getThumb(user.jwt, order.id);
    // setThumbURL(thumbURL);
    // URL.revokeObjectURL(thumbURL);
    setThumbURL(await getThumb(user.jwt, order.id));
  }, [user.jwt, order.id]);

  React.useEffect(() => {
    if (order.rendering.state === 'done')
      initThumb();
  }, [initThumb, order.rendering.state]);

  const handleDownload = async () => {
    setDownloading(true);
    const delivery = await getDelivery(user.jwt, order.id, (e) => { setProgress(e.progress?e.progress:0) });
    const a = document.createElement('a');
    a.href = delivery[0];
    a.download =
      'order_' + order.id + '_' +
      slugify(shooting.outfit.name) + '_' + slugify(shooting.name) + '_' +
      mode.name + '.' + delivery[1];
    a.click();
    setDownloading(false);
    URL.revokeObjectURL(delivery[0]);
  };

  const w = '256px'
  const h = '484px'
  return (
    <Card sx={{ border: 'solid 1px lightblue', borderRadius: 2 }}>
      {/* <CardActionArea
        disabled={order.rendering.state !== 'done'}
        // onClick={e => {
        //   navigate('/shooting/'+shooting.id);
        // }}
        sx={{ width: '512px', height: '640px', display: 'flex', alignItems: 'flex-start' }}
      > */}
        <Box bgcolor='palette.text.secondary' display='flex' justifyContent='center' alignItems='center' sx={{ width: w, height: w}}>
          {order.rendering.state === 'pending' ?
            <Typography color='#ffeb3b'>
              Pending
            </Typography>
          : order.rendering.state === 'processing' ?
            <Typography color='#4caf50'>
              Processing
              {/* <CircularProgress /> */}
            </Typography>
          : order.rendering.state === 'done' ?
            <CardMedia
              component='img'
              title='TITLE'
              alt='ALT'
              src={thumbURL}
              height={w}
              sx={{ flex: '0 0 0 '+{w}, objectFit: 'contain' }}
            />
          :
            <Typography color='#d50000'>
              Failed
            </Typography>
         }
        </Box>
        <CardContent sx={{ flex: '0 1', pt: 0.5, pb: 0.5}}>
          <Typography component='div' variant='h5'>
            Order {order.id}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
            Ordered: {dateCreated.toLocaleString('en-US')}<br/>
            Provided: {order.providedOn?dateProvided.toLocaleString('en-US'):'N/A'}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color='text.secondary'>
            Mode: {mode.name}<br/>
            Target: {mode.targets[order.target].name}
          </Typography>
        </CardContent>
          {/* <LoadingButton
            size='small'
            type='submit'
            variant='contained'
            // onClick={handleOrder}
          >
            Download
          </LoadingButton> */}
        {downloading?
          <LinearProgress
            variant='determinate'
            value={100*progress}
            sx={{ m: 2, p: 0.6, display:'flex' }}
          />
        :
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size='small' variant='contained'
                disabled={order.rendering.state !== 'done'}
                onClick={handleDownload}
              >
                Download
              </Button>
          </CardActions>
        }
      {/* </CardActionArea> */}
    </Card>
  );
}

// Default export
export default OrderCard;
