export const CMS_URL = process.env.REACT_APP_CMS_URL;
// export const API_URL = process.env.REACT_APP_API_URL;
// export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const cmsEndpoints = {
  auth: '/api/auth/local',
  resetPassword: '/api/auth/reset-password',
  forgotPassword: '/api/auth/forgot-password',
  models: '/api/models',
  poses: '/api/poses',
  outfits: '/api/outfits',
  outfitsThumb: '/api/outfits/:id/thumb',
  shootings: '/api/shootings',
  shootingsUpload: '/api/shootings/:id/upload',
  shootingsPreview: '/api/shootings/:id/preview',
  orders: '/api/orders',
  ordersDelivery: '/api/orders/:id/delivery',
  ordersThumb: '/api/orders/:id/thumb',
  classes: '/api/classes',
};

export const apiEndpoints = {
};

// export const normalizeData = (data) => {
//   const isObject = (data) =>
//     Object.prototype.toString.call(data) === '[object Object]'
//   const isArray = (data) =>
//     Object.prototype.toString.call(data) === '[object Array]'

//   const flatten = (data) => {
//     if (!data.attributes) return data

//     return {
//       id: data.id,
//       ...data.attributes
//     }
//   }

//   if (isArray(data)) {
//     return data.map((item) => normalizeData(item))
//   }

//   if (isObject(data)) {
//     if (isArray(data.data)) {
//       data = [...data.data]
//     } else if (isObject(data.data)) {
//       data = flatten({ ...data.data })
//     } else if (data.data === null) {
//       data = null
//     } else {
//       data = flatten(data)
//     }

//     for (const key in data) {
//       data[key] = normalizeData(data[key])
//     }

//     return data
//   }

//   return data
// }
