// External imports
import React from 'react'

// import TextField from '@mui/material/TextField';
// import Slider from '@mui/material/Slider';

// Local imports

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { AlertProps } from '@/store/alert';
import { Layer, Shooting, getImage } from '@/store/shooting';

//////////////////////////////////////////////////////////////////////////////////////////

// Component props
interface CanvasProps {
  setAlert: React.Dispatch<React.SetStateAction<AlertProps | null>>,
  shooting: Shooting,
  modeOption: string,
  layers: Layer[],
  factors: number[],
}

interface Buffers {
  position: WebGLBuffer,
  textureCoord: WebGLBuffer,
  index: WebGLBuffer,
}

const gamma = '2.0'

const vsSource = `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;

varying highp vec2 vTextureCoord;

void main(void) {
	gl_Position = aVertexPosition;
	vTextureCoord = aTextureCoord;
}
`;

const fsSource = `
varying highp vec2 vTextureCoord;
precision mediump float;

PARAMETERS

void main(void) {
  vec3 result = vec3(0.0);
  CODE
  gl_FragColor = vec4(pow(result, vec3(1.0/GAMMA)), 1.0);
}
`;

function createFragmentShader(layers: Layer[]) {
  let parameters = '';
  let code = '';

  layers.forEach((layer) => {
    parameters += 'uniform sampler2D s_'+layer.id+';'+((layer.controllable)?'uniform float f_'+layer.id+';':'');
    code += 'result+='+((layer.controllable)?'f_'+layer.id+'*':'')+'pow(texture2D(s_'+layer.id+', vTextureCoord).xyz, vec3(GAMMA));'
    //code += 'result+=pow(texture2D(s_'+item.id+', vTextureCoord).xyz, vec3(GAMMA));'
  })
  // code += 'result += pow(texture2D(s_bg, vTextureCoord).xyz, vec3(GAMMA));'
  return fsSource.replace('PARAMETERS', parameters).replace('CODE', code).replaceAll('GAMMA', gamma)
}

function loadShader(gl: WebGL2RenderingContext, type: number, source: string) {
  const shader = gl.createShader(type);

  if (shader) {
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.log('An error occurred compiling the shaders: '+gl.getShaderInfoLog(shader));
      // if (setAlert)
      //   setAlert( { severity: 'error', text: 'An error occurred compiling the shaders: '+gl.getShaderInfoLog(shader) } );
      gl.deleteShader(shader);
      return null;
    }
    return shader;
  }
  return null;
}

function initBuffers(gl: WebGL2RenderingContext) {
  const positions = [-1.0, -1.0, 0.0, 1.0, -1.0, 0.0, 1.0, 1.0, 0.0, -1.0, 1.0, 0.0];
  const textureCoordinates = [0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 0.0];
  const indices = [0, 3, 2, 0, 1, 2];
  const positionBuffer = gl.createBuffer();
  const textureCoordBuffer = gl.createBuffer();
  const indexBuffer = gl.createBuffer();

  if (!positionBuffer || !textureCoordBuffer || !indexBuffer) {
    console.log('An error occurred compiling the shaders.');
    // if (setAlert)
    //    setAlert( { severity: 'error', text: 'An error occurred compiling the shaders.' } );
    return null;
  } else {
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), gl.STATIC_DRAW);
    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);
    return {
      position: positionBuffer,
      textureCoord: textureCoordBuffer,
      index: indexBuffer,
    };
  }
}

// function disposeBuffers(gl: WebGL2RenderingContext, buffers: Buffers) {
//   console.log('disposeBuffers');
//   gl.deleteBuffer(buffers.position);
//   gl.deleteBuffer(buffers.textureCoord);
//   gl.deleteBuffer(buffers.index);
// }

function initTextures(gl: WebGL2RenderingContext, images: ImageBitmap[]) {
  const textures: (WebGLTexture | null)[] = [];

  images.forEach((image, index) => {
    textures[index] = gl.createTexture();
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, textures[index]);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, image);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  })
  return textures;
}

// function disposeTextures(gl: WebGL2RenderingContext, textures: (WebGLTexture | null)[]) {
//   console.log('disposeTextures');
//   textures.forEach(async (texture, index) => {
//     gl.deleteTexture(texture);
//   })
// }

function initShaderProgram(gl: WebGL2RenderingContext, layers: Layer[]) {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, createFragmentShader(layers));

  if (vertexShader && fragmentShader) {
    const shaderProgram = gl.createProgram();

    if (shaderProgram) {
      gl.attachShader(shaderProgram, vertexShader);
      gl.attachShader(shaderProgram, fragmentShader);
      gl.linkProgram(shaderProgram);

      if (gl.getProgramParameter(shaderProgram, gl.LINK_STATUS))
        return shaderProgram;
      else {
        console.log('Unable to initialize the shader program.');
        // if (setAlert)
        //   setAlert( { severity: 'error', text: 'Unable to initialize the shader program.' } );
      }
    }
  }
  return null;
}

function drawScene(
  gl: WebGL2RenderingContext,
  buffers: Buffers,
  program: WebGLProgram,
  layers: Layer[],
  texs: WebGLTexture[],
  factors: number[],
) {
  const vertexPosition = gl.getAttribLocation(program, 'aVertexPosition');
  const textureCoord = gl.getAttribLocation(program, 'aTextureCoord');

  gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);
  gl.vertexAttribPointer(vertexPosition, 3, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(vertexPosition);
  gl.bindBuffer(gl.ARRAY_BUFFER, buffers.textureCoord);
  gl.vertexAttribPointer(textureCoord, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(textureCoord);
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffers.index);
  gl.useProgram(program);
  layers.forEach((layer, index) => {
    gl.activeTexture(gl.TEXTURE0+index);
    gl.bindTexture(gl.TEXTURE_2D, texs[index]);
    gl.uniform1i(gl.getUniformLocation(program, 's_'+layer.id), index);
    if (layer.controllable)
      gl.uniform1f(gl.getUniformLocation(program, 'f_'+layer.id), factors[index]);
  })
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
}

// Component definition
function Canvas( { setAlert, shooting, modeOption, layers, factors }: CanvasProps ) {
  const user = React.useContext(UserStore);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [images, setImages] = React.useState<ImageBitmap[]>([]);
  // const [resolution, setResolution] = React.useState<[number, number]>([0, 0]);
  const [context, setContext] = React.useState<WebGL2RenderingContext | null>(null);
  const [textures, setTextures] = React.useState<(WebGLTexture | null)[]>([]);
  const [buffers, setBuffers] = React.useState<Buffers | null>(null);
  const [program, setProgram] = React.useState<WebGLProgram | null>(null);

  const initImages = React.useCallback(async () => {
    const i: Promise<ImageBitmap>[] = [];

    layers.forEach((item, index) => {
      i[index] = getImage(user.jwt, shooting.id, modeOption, item.id);
    });

    const images = await Promise.all(i);
    setImages(images);
    // setResolution( [images[0].width, height: images[0].height} );
    // for await (const image of textures.map(
    //   item => getImage(user.jwt, shooting.id, item.id))) {
    //   images[0] = image
    // }
  }, [user.jwt, shooting.id, modeOption, layers]);

  React.useEffect(() => {
    initImages();
  }, [initImages]);

  React.useEffect(() => {
    if (images) {
      const canvas = canvasRef.current;

      if (canvas) {
        const glContext = canvas.getContext('webgl2');

        if (glContext) {
          const texs = initTextures(glContext, images);
          const buffers = (initBuffers(glContext));
          const program = initShaderProgram(glContext, layers);

          setContext(glContext);
          setTextures(texs);
          setBuffers(buffers);
          setProgram(program);
        }
      }
    }
    // return () => { if (glContext) { disposeTextures(glContext); disposeBuffers(glContext, buffers); } }
  }, [canvasRef, layers, images]);

  React.useEffect(() => {
    if (context && buffers && program && textures.length > 0 && textures.every(item => item !== null))
        drawScene(context, buffers, program, layers, textures as WebGLTexture[], factors);
  }, [context, buffers, program, layers, textures, factors])

  return (
    <>
      { (images.length > 0) ?
          <canvas ref={canvasRef} width={images[0].width} height={images[1].height} />
        :
          null
      }
    </>
  );
}

// Default export
export default Canvas;
