import React from 'react';
import { useParams } from 'react-router-dom';
// import { useMeasure } from 'react-use';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
// import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
import ResetIcon from '@mui/icons-material/RestartAlt';

// Local imports
import Canvas from '@/components/Canvas';
import MainAppBar from '@/components/MainAppBar';
import OrderDialog from '@/dialogs/OrderDialog';

//////////////////////////////////////////////////////////////////////////////////////////

import { UserStore } from '@/store';
import { AlertProps } from '@/store/alert';
import { Layer, Shooting, getShooting } from '@/store/shooting';

//////////////////////////////////////////////////////////////////////////////////////////

// Component definition
function OrderPage() {
  const user = React.useContext(UserStore);
  const { id, name } = useParams();
  const [alert, setAlert] = React.useState<AlertProps | null>(null);
  const [shooting, setShooting] = React.useState<Shooting>();
  const [mode, setMode] = React.useState<string>('');
  const [layers, setLayers] = React.useState<Layer[]>([]);
  const [factors, setFactors] = React.useState<number[]>([]);
  const [targets, setTargets] = React.useState<boolean[]>([]);
  const [confirmOrder, setConfirmOrder] = React.useState<boolean>(false);

  // const modeIndex = shooting?shooting.configuration.modes.map(mode => mode.option).indexOf(mode):0;

  function setFactor(index: number, v: number) {
    const f = [...factors];
    f[index] = v;
    setFactors(f);
  }

  const init = React.useCallback(async (id: string) => {
    const shooting = await getShooting(user.jwt, Number(id));
    // console.log('--------------------------');
    // console.log(shooting);
    // console.log('--------------------------');
    setShooting(shooting);
    setMode('a');
    const l: Layer[] = [];
    const f: number[] = [];
    Object.entries(shooting.configuration.general).forEach(([layerId, layer]) => {
      l.push( { id: layerId, name:layer.name, controllable: layer.factor != null } );
      f.push(layer.factor ? layer.factor : 1);
    });
    Object.entries(shooting.configuration.modes.a.configuration).forEach(([layerId, layer]) => {
      l.push( { id: layerId, name:layer.name, controllable: layer.factor != null } );
      f.push(layer.factor ? layer.factor : 1);
    });
    setLayers(l);
    setFactors(f);
    setTargets(Array(Object.keys(shooting.configuration.modes.a.targets).length).fill(false));
  }, [user.jwt]);

  React.useEffect(() => {
    if (id)
      init(id);
  }, [init, id]);

  const handleModeChange = ((event: React.ChangeEvent<HTMLInputElement>) => {
    if (shooting) {
      // const modeIndex = shooting.configuration.modes.map(mode => mode.option).indexOf(event.target.value);
      const newMode = event.target.value;
      const generalLayers = Object.keys(shooting.configuration.general).length;

      const l = layers.slice(0, generalLayers);
      const f = factors.slice(0, generalLayers);
      Object.entries(shooting.configuration.modes[newMode].configuration).forEach(([layerId, layer]) => {
        l.push( { id: layerId, name:layer.name, controllable: layer.factor != null } );
        f.push(layer.factor ? layer.factor : 1);
      });
      setMode(newMode);
      setLayers(l);
      setFactors(f);
      setTargets(Array(Object.keys(shooting.configuration.modes[newMode].targets).length).fill(false));
    }
  });

  const handleTargetChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const t = [...targets];
    t[index] = event.target.checked;
    setTargets(t);
  };

  const handleOrder = (() => {
    setConfirmOrder(true);
  });

  if (shooting)
    return (
      <React.Fragment>
        <OrderDialog
          confirmOrder={confirmOrder}
          setConfirmOrder={setConfirmOrder}
          shooting={shooting}
          mode={mode}
          targets={targets}
          layers={layers}
          factors={factors}
        />
        <MainAppBar enableBack>
          <TextField sx={{ flexGrow: 1 }}
            margin='dense' size='small' label='Outfit'
            value={shooting.outfit.name}
            InputProps={{ readOnly: true }}
          />
          <Box sx={{ flexGrow: 0.1 }}/>
          <TextField sx={{ flexGrow: 1 }}
            margin='dense' size='small' label='Shooting'
            value={shooting.name}
            InputProps={{ readOnly: true }}
          />
          {/* <Typography component='div' variant='h5'>
            {shooting.name}
          </Typography> */}
        </MainAppBar>

        <Container sx={{ }} >
          <Box
            sx={{ my: 4 }}
          >
            <Collapse in={alert?true:false}>
              <Alert
                {...alert}
                variant='outlined'
                action={
                  <IconButton
                    aria-label='close' color='inherit' size='small'
                    onClick={() => { setAlert(null); }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {alert?alert.text:''}
              </Alert>
            </Collapse>

            <Grid container spacing={2}>
              <Grid>
                <Canvas
                  setAlert={setAlert}
                  shooting={shooting}
                  modeOption={shooting.configuration.modes[mode].option}
                  layers={layers}
                  factors={factors}/>
              </Grid>
              {/* <Grid xs={1}></Grid> */}
              <Grid xs={3} sx={{ flexGrow: 1 }}>
                <Stack spacing={2}>
                  <TextField
                    select size='small' label='Mode'
                    value={mode}
                    onChange={handleModeChange}
                  >
                    {Object.entries(shooting.configuration.modes).map(([modeKey, mode], index) => (
                      <MenuItem key={index} value={modeKey}>{mode.name}</MenuItem>
                    ))}
                  </TextField>
                  <Divider />
                  {layers.map((layer, index) => {
                    if (layer.controllable)
                      return (
                        <Stack key={index}>
                          <Typography>
                            {layer.name}
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Slider
                              sx={{ flexGrow: 1 }}
                              size='small' valueLabelDisplay='auto'
                              min={0} max={10} step={0.01}
                              value={factors[index] as number}
                              onChange={ (_, v) => setFactor(index, v as number) }
                            />
                            <IconButton
                              aria-label='reset' color='inherit'
                              onClick={ () => setFactor(index, 1.0) }
                            >
                              <ResetIcon fontSize='inherit' />
                            </IconButton>
                          </Stack>
                        </Stack>
                      );
                    else
                      return null;
                  })}
                  <Divider />
                  <FormGroup>
                    {Object.entries(shooting.configuration.modes[mode].targets).map(([_, target], index) => (
                      <FormControlLabel
                        key={index}
                        label={target.name}
                        control={ <Checkbox checked={targets[index]} onChange={(e) => handleTargetChange(index, e)}/> }
                      />
                    ))}
                  </FormGroup>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    disabled={targets.every(t => !t)}
                    onClick={handleOrder}
                  >
                    Order
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
            {/* <MainContent>
              <ProjectsView/>
            </MainContent>

            <Footer>
              <Copyright />
            </Footer> */}
          </Box>
        </Container>
      </React.Fragment>
    );
  else
    return null;
}

// Default export
export default OrderPage;
