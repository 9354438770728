import axios from 'axios';
import { cmsEndpoints, CMS_URL } from '@/config/config';

export interface Outfit {
  id: number;
  createdAt: string;
  name: string;
  slug: string;
  image: string;
}

export interface ModelData {
  id: number;
  createdAt: string;
  name: string;
  slug: string;
}

// export interface OutfitData {
//   label: string;
// }

export interface PoseData {
  id: number;
  name: string;
  slug: string;
  shotAt: string;
}

interface _PoseData {
  id: number;
  attributes: {
    name: string;
    slug: string;
    createdAt: string;
  }
}

export async function getOutfits(
  userJwt: string | undefined) {
// : Promise<AxiosResponse<any, any>> {
  const _outfits: Outfit[] = [];

  const response = await axios.get(`${CMS_URL}${cmsEndpoints.outfits}?sort[0]=name&sort[1]=createdAt`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
  });
  if (response.status === 200) {
    const data = response.data;

    // console.log(data);
    data.data.forEach((item: _PoseData) => {
      _outfits.push({
        id: item.id,
        name: item.attributes.name,
        slug: item.attributes.slug,
        createdAt: item.attributes.createdAt,
        image: 'None'} );
    })
  }
  return { response: response, outfits: _outfits };
}

export async function addOutfit(userJwt: string | undefined, name: string) {
  // check label
  return await axios.post(`${CMS_URL}${cmsEndpoints.outfits}`,
    {
      data: {
        name: name,
      },
    }, {
      headers: {
        'Authorization': `Bearer ${userJwt}`,
      },
    },
  );
}

export async function getThumb(userJwt: string | undefined, outfitId: number) {
  const response = await axios.get(`${CMS_URL}${cmsEndpoints.outfitsThumb.replace(':id', outfitId.toString())}`, {
    headers: {
      'Authorization': `Bearer ${userJwt}`,
    },
    responseType: 'blob',
  })
  if (response.status === 200)
    return URL.createObjectURL(response.data);
}
