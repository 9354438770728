/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
//import { ConstructionOutlined } from '@mui/icons-material';
// import { useMeasure } from 'react-use';
import Grid from '@mui/material/Unstable_Grid2';

// import Paper from '@mui/material/Paper';
// import Stack from '@mui/material/Stack';

// Local imports
import OrderCard from '@/components/OrderCard';

//////////////////////////////////////////////////////////////////////////////////////////

import { Order } from '@/store/order';
import { Shooting } from '@/store/shooting';

//////////////////////////////////////////////////////////////////////////////////////////

export interface OrderCardGridProps {
  shooting: Shooting
}

function ShootingCardGrid( { shooting }: OrderCardGridProps ) {
  // const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Grid container spacing={2}>
      {(shooting.orders as Order[]).map((order, index) => {
        return (
          <Grid key={index}>
            <OrderCard shooting={shooting} order={order} />
          </Grid>
        )
      })}
    </Grid>
  );
}

// Default export
export default ShootingCardGrid;
