/**
 * The ImageButton component renders an alert with
 * the package name, version and environment.
 */

// External imports
//import { ConstructionOutlined } from '@mui/icons-material';
import OutfitCard from '@/components/OutfitCard';
import { useMeasure } from 'react-use';
import Grid from '@mui/material/Unstable_Grid2';

// Local imports
import { Outfit } from '@/store/outfit';

export interface OutfitCardGridProps {
  text: string,
  items: Outfit[],
}

function OutfitCardGrid( { text, items }: OutfitCardGridProps ) {
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  return (
    <Grid ref={ref} container spacing={2}>
      {items.map((item, index) => {
        return (
          <Grid key={index} style={{width: width/6}}>
            <OutfitCard outfit={item} />
          </Grid>
        )
      })}
    </Grid>
  );
}

// Default export
export default OutfitCardGrid;
