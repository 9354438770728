import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserStore } from '@/store';

function PrivateRoute() {
  const user = React.useContext(UserStore);

  //console.log('User is ' + (!user.isLoggedIn() ? 'not ' : '') + 'logged in.')
  return user.isLoggedIn() ? <Outlet /> : <Navigate to='/login' />;
}

export default PrivateRoute;
